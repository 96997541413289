import React, { Component } from 'react'
import Busqueda from './components/Busqueda';
import Decretos from './components/Decretos';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from "react-router-dom";
import Home from './components/Home';
import * as Icon from 'react-bootstrap-icons';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-186302655-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


export default class App extends Component {

  

 state = {
   ClaroOscuro :'dark'

 };

  render() {
    return (


    <div className="app container">
    <Router>
      <div className="container">
        <nav className="navbar navbar-expand-lg  sticky-top bg-primary">
        <div className="btn-group container-fluid">
        
          <NavLink to="/home" className="btn btn-primary" activeClassName="active">
            <Icon.HouseDoorFill fontSize="2rem"/> Inicio
          </NavLink>
          <NavLink to="/decretos" className="btn btn-primary" activeClassName="active">
            <Icon.Bank  fontSize="2rem" /> Decretos y topes
          </NavLink>
          <NavLink to="/buscador" className="btn btn-primary" activeClassName="active">
            <Icon.Search  fontSize="2rem" /> Busca resenas
          </NavLink>

        </div>
        </nav>
        <div className="">
        <Routes>
          <Route path="/"  element={<Home/>}/>
          <Route path="/home"  element={<Home/>}/>
          <Route  path="/buscador"  element={<Busqueda/>} />
          <Route  path="/decretos"  element={<Decretos/>} />
        </Routes>
        </div>   
        </div>   
    </Router>

    
    </div>
    )
  }
}
