import React, { Component } from 'react'

import  Buscador  from './Buscador';
import Resultado from './Resultado';

export default class Busqueda extends Component {


    state ={
        termino:'',
        imagenes:[],
        pagina:''
      }
    
      scroll = () => {
        const elemento = document.querySelector('.jumbotron');
        elemento.scrollIntoView('smooth', 'start');
      }
    
      paginaAnterior = () => {
        //console.log('Siguiente ...');
        //leer state pagina actual
        let pagina = this.state.pagina;
       //si la pagina es 1 y no ir atras
       if (pagina === 1 ) return null;
    
        //sumar 1 a pagina actual
        pagina -=1;
        //agreagra cambio a state
        this.setState({
          pagina
        }, () => { 
          this.consultarApi();
          this.scroll();
       } );
        
       // console.log(pagina);
    
      
      }
    
      paginaSiguiente = () => {
        //console.log('Siguiente ...');
        //leer state pagina actual
        let pagina = this.state.pagina;
        //sumar 1 a pagina actual
        pagina +=1;
        //agreagra cambio a state
        this.setState({
          pagina
        }, () => { 
            this.consultarApi();
            this.scroll();
         } );
     
       // console.log(pagina);
    
      }
      
      consultarApi = () =>{
        const termino = this.state.termino;
        const pagina =this.state.pagina;
        const url=`https://pixabay.com/api/?key=1732750-d45b5378879d1e877cd1d35a6&q=${termino}&per_page=30&page=${pagina}`;
        
        //console.log(url);
        fetch(url)
          .then(respuesta => respuesta.json())
          .then(resultado => this.setState({imagenes : resultado.hits}))
      }
    
      datosBusqueda = (termino) => {
    
        this.setState({
          termino:termino,
          pagina:1
        }, () => {
          this.consultarApi();
        })
      }


    render() {
        return (
            <div>
                <div className="jumbotron">
                <p className="leed text-center"><i class="fas fa-search fa-3x"></i></p>
                <Buscador 
                  datosBusqueda={this.datosBusqueda}
                />
              </div>
          {this.state.termino}
              <div className="row justify-content-center"> 
              <Resultado 
                  imagenes={this.state.imagenes}
                  paginaAnterior={this.paginaAnterior}
                  paginaSiguiente={this.paginaSiguiente}
                />
                </div>
            </div>
        )
    }
}
