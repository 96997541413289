
import React, { Component } from 'react'
import { Card, Image, Alert } from 'react-bootstrap';


export default class Decretos extends Component {

    render() {
        return (
            <div><br/><br/>
            <Card>
                <Alert>
                    El Gobierno Nacional ha creado desde 2020 varios programas sociales y estrategias para solventar la crisis económica por la que afronta el país por cuenta de la pandemia del covid-19.<br/>
                    Por lo anterior, el presidente de la República, Iván Duque, anunció la puesta en marcha de los tres días sin IVA, una iniciativa que tiene como objetivo la reactivación del comercio y busca que los colombianos puedan adquirir productos sin el cobro de este impuesto.
                    <br/>
                    <b>En ese sentido, el Gobierno anunció que los días sin IVA en 2022 se llevarán a cabo el 11 de Marzo del 2022, 17 de junio del 2022, 2 de diciembre del 2022 </b>                
                </Alert>
                <Alert variant='secondary'>
                    <h3>Estas fechas son muy importantes para dinamizar la economía y recuperar el empleo en el país.</h3><br/>
                    <b>Cuál es el límite de compra de los bienes cubiertos por el día sin IVA?</b>
                    El consumidor puede adquirir hasta tres unidades del mismo género, vendido por el mismo responsable. Superarlas genera la pérdida del beneficio. Cuando se trate de productos que vienen en pares, se entenderá que ese par corresponde a una unidad.<br/><br/>
                    <b>Qué formas de pago aplican para beneficiarse del día sin IVA? </b>
                    Se pueden hacer pagos en efectivo o a través de tarjetas débito, crédito y otros mecanismos electrónicos donde intervenga una entidad vigilada por la Superfinanciera.
                    <b>¿Qué artículos se podrán comprar en los comercios sin pagar el impuesto IVA? </b>
                    Se podrán comprar los siguientes bienes, cuyo precio por unidad no supere un número específico de Unidades de Valor Tributario UVT. El beneficio aplica máximo para tres unidades de cada tipo de bien. El valor de cada UVT para 2022 es de $38.004.
                </Alert>
                <img src="./images/dia_sin_iva_2022.png" alt="Dia sin Iva" height="543" width="321" />
            </Card>
            </div>
        )
    }
}
