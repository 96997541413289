import React, { useState } from 'react'
import { Card, InputGroup, FormControl, Alert } from 'react-bootstrap';



export default function Home() {

  const [number1, setNumber1] = useState (0); 
  const [result, setResult] = useState(number1 / 1.19);
  const [diff, setDiff] = useState(0);

  const handleChange = value => {
    setNumber1(value)
    setResult(value / 1.19)
    setDiff(value - (value / 1.19))
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const header = {
    color: "black",
  /*   backgroundColor: "DodgerBlue", */
    padding: "10px",
    fontFamily: "Arial",
    textAlign: "center"
  };

  const content = {
    color: "black",
    /* backgroundColor: "DodgerBlue", */
    padding: "10px",
    fontFamily: "Arial",
    textAlign: "center"
  };

  return (
  <>
    <br>
    </br>
    <Alert variant='success' style={{textAlign:'center'}}>
      <h2>
        11 de Marzo del 2022<br/>17 de junio del 2022<br/>2 de diciembre del 2022
      </h2>
      <h4>
        Recuerda que el precio máximo para que aplique la exención de IVA es de $3.040.000 u 80 UVT., vea los topes por sector <a href='./decretos'> Aqui </a>
      </h4>
    </Alert>
    <br/>
    <Card>
      <Card.Body>
        <h2 style={header}>
        Ingrese el precio del producto a calcular:
        </h2>
      <label style={{ display: "flex",
                      justifyContent: "center",
                      alignItems: "center"}}> 
      <InputGroup className="mb-3"  onChange = {e => handleChange(e.target.value)} style={{fontSize: 50}}>
        <InputGroup.Text>$</InputGroup.Text>
        <FormControl aria-label="Monto del Producto." />
        <InputGroup.Text>.00</InputGroup.Text>
      </InputGroup>
      </label>
      <Alert variant='primary'>
        <h1 style={content}>
          <label> 
            El valor con IVA es: $ {numberWithCommas(number1)}
          </label>
      </h1>
      </Alert>
        <Alert variant='info'>
        <h1 style={content}>
            <label> 
              El valor Ahorrado es: $ {numberWithCommas(diff.toFixed(2))}
            </label>
        </h1>
      </Alert> 
      <Alert variant='warning'>
          <h1 style={content}>
            <label> 
            El valor sin IVA es: $ {numberWithCommas(result.toFixed(2))}
            </label>
          </h1>
        </Alert>

        <br/>
        <div style={{textAlign:'center', alignContent:'center'}}> <h2><b> Puede obtener mas informacion en la seccion de topes y decretos <a href='./decretos'> Link </a></b></h2> </div>
        <br/><br/>
        <div style={{textAlign:'center', alignContent:'center'}}> <b> Alberto Ferro - <a href='http://purosoft.net'> Purosoft.net </a></b> </div>
     
      </Card.Body>
    </Card>
  </>
  )
}
