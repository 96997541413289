import React from 'react';
import * as Icon from 'react-bootstrap-icons';

const Imagen =(props) => {

    const {largeImageURL, likes, tags, webformatURL} = props.imagen;
    return(
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1  py-0">
        <div className="card ">  
            <a href={largeImageURL} target="_blank">
                <img src={webformatURL} alt={tags} className="card-img-top" />
            </a>
            <div className="container">
                <div className="row px-1  py-1"> 
                    <div className="col-8"><Icon.Heart /> {likes}</div>
                    <div className="col-sm row"> 
                       <div className="px-1  py-0"> <Icon.Whatsapp /> </div>
                       <div className="px-1  py-0"> <Icon.Facebook /> </div> 
                       <div className="px-1  py-0"> <Icon.Instagram /> </div>
                    </div>
                <div className="cart-text">   
                    {tags}
                 </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default Imagen;
